exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campanha-js": () => import("./../../../src/pages/campanha.js" /* webpackChunkName: "component---src-pages-campanha-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lista-js": () => import("./../../../src/pages/lista.js" /* webpackChunkName: "component---src-pages-lista-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-servicos-hospedagem-js": () => import("./../../../src/pages/servicos/hospedagem.js" /* webpackChunkName: "component---src-pages-servicos-hospedagem-js" */),
  "component---src-pages-servicos-index-js": () => import("./../../../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

